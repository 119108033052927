.main {
  position: relative;
  margin-top: 100px;
  @media screen and (max-width: 1440px) {
    margin-top: 71px;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 67px;
  }
}

section {
  padding: 80px 0;
  @media screen and (max-width: 1440px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 860px) {
    padding: 40px 0;
  }
  &.hero {
    width: 100%;
    height: calc(100vh - 100px);
    padding: 0;
    @media screen and (max-width: 1440px) {
      height: calc(100vh - 70px);
    }
    .content {
      p {
        line-height: 28px;
      }
      .link {
        @media screen and (max-height: 700px) {
          margin-top: 30px;
        }
      }
    }
    @media screen and (max-width: 860px) {
      height: 320px;

      .link {
        margin-top: 50px;
      }
    }
    @media screen and (max-width: 640px) {
      height: 240px;
    }

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .content {
            position: absolute;
            left: 30%;
            top: 63%;
            transform: translate(-50%, -50%);
            max-width: 550px;
            width: 100%;
            text-align: left;
            color: $color--white;
            @media screen and (max-width: 1440px) {
              max-width: 360px;
              p {
                line-height: 20px;
                font-size: 12px;
              }
            }

            @media screen and (max-width: 860px) {
              max-width: 280px;
              left: 50%;
              top: 50%;
              p {
                max-height: 90px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      [class*='swiper-button-'] {
        outline: none;
      }
      .swiper-button-next {
        background-image: url(../img/ico-chevron-right-white.svg);
        right: 70px;
        @media screen and (max-width: 1366px) {
          right: 40px;
        }
        @media screen and (max-width: 860px) {
          right: 10px;
          transform: scale(0.6);
        }
      }
      .swiper-button-prev {
        background-image: url(../img/ico-chevron-left-white.svg);
        left: 70px;
        @media screen and (max-width: 1366px) {
          left: 40px;
        }
        @media screen and (max-width: 860px) {
          left: 10px;
          transform: scale(0.6);
        }
      }
      .swiper-pagination.hero {
        bottom: 22px;
        .swiper-pagination-bullet {
          width: 124px;
          height: 1px;
          overflow: hidden;
          background-color: $color--white;
          opacity: 1;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 1px;
            width: 0%;
            background-color: $color--red;
          }
          @keyframes loading {
            from {
              width: 0;
            }
            to {
              width: 100%;
            }
          }
          &-active {
            &:after {
              animation: loading 3s 0.5s forwards linear;
            }
          }
          @media screen and (max-width: 860px) {
            width: 80px;
          }
          @media screen and (max-width: 640px) {
            width: 40px;
          }
        }
      }
    }
  }
  &.about {
    padding: 0;
    .flex-container {
      @media screen and (max-width: 860px) {
        flex-wrap: wrap;
      }
      .block {
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: block;
        transition-duration: 0.3s;
        position: relative;
        @media screen and (max-width: 1440px) {
          height: 233px;
        }
        @media screen and (max-width: 860px) {
          height: 200px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: hsla(0, 0%, 19%, 0.8);
          transition-duration: 0.3s;
        }
        &:after {
          content: url(../img/ico-plus-white.svg);
          position: absolute;
          width: 32px;
          height: 32px;
          bottom: 48px;
          right: 52px;
          transition-duration: 0.8s;
          @media screen and (max-width: 860px) {
            transform: scale(0.7);
            bottom: 10px;
            right: 10px;
          }
        }
        h1 {
          margin-bottom: 10px;
          margin-top: 10px;
        }
        p {
          @media screen and (max-width: 1440px) {
            max-width: 350px;
          }
        }
        &:hover {
          width: 110%;
          &:before {
            background-color: rgba(86, 86, 86, 0.84);
          }
          &:after {
            transform: rotate(180deg);
          }
        }
        &.left {
          background-image: url(../img/img-about1.jpg);
        }
        &.right {
          background-image: url(../img/img-about2.jpg);
        }
        .content {
          width: 100%;
          max-width: 497px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $color--white;
          @media screen and (max-width: 1024px) {
            max-width: 360px;
          }
          @media screen and (max-width: 860px) {
            max-width: 300px;
          }
        }
      }
    }
  }
  &.product {
    // padding-bottom: 0;

    .container {
      position: relative;
      margin: auto;
      width: 100%;
      & > h1 {
        @media screen and (max-width: 860px) {
          text-align: center;
        }
      }
      .row {
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width: 640px) {
          flex-direction: column;
        }
        .card {
          margin-bottom: 16px;
          @media screen and (max-width: 1440px) {
            margin-bottom: 9px;
          }
          @media screen and (max-width: 1366px) {
            // width: calc(50% - 0.7vw);
          }
          @media screen and (max-width: 860px) {
            // width: calc(50% - 0.7vw);
            width: 240px;
            height: 185px;
            margin-bottom: 5px;
          }
          @media screen and (max-width: 640px) {
            width: 100%;
            height: 320px;
          }
          .content {
            @media screen and (max-width: 860px) {
              left: 20px;
              bottom: 12px;
              h1 {
                font-size: 50px;
                margin-bottom: 32px;
              }
              h4 {
                font-size: 16px;
              }
            }
            @media screen and (max-width: 640px) {
              bottom: 32px;
              h1 {
                margin-bottom: 21px;
              }
              h4 {
                font-size: 32px;
              }
            }
          }
        }
      }
    }
  }
  &.whatson {
    & > .flex-container {
      & > .block {
        position: relative;
        & > h4 {
          @media screen and (max-width: 860px) {
            font-size: 20px;
            padding: 10px 20px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba($color--black, $alpha: 0.75);
            z-index: 99;
            margin: 0;
            color: $color--white;
          }
        }
        & > .link {
          @media screen and (max-width: 860px) {
            position: absolute;
            padding: 10px;
            right: 0;
            top: 0;
            background-color: $color--white;
            z-index: 99;
            margin: 0;
          }
        }
      }
    }
    .flex-container {
      @media screen and (max-width: 860px) {
        flex-wrap: wrap;
      }
      .card {
        @media screen and (max-width: 860px) {
          max-height: 320px;
          margin-bottom: 50px;
        }
        h4 {
          @media screen and (max-width: 860px) {
            font-size: 20px;
          }
        }
        .content {
          @media screen and (max-width: 860px) {
            padding: 10px 20px;
          }
        }
      }
    }
  }
  &.contact {
    padding-bottom: 0;
    .flex-container {
      @media screen and (max-width: 860px) {
        flex-wrap: wrap;
      }
    }
    .block {
      border: 1px solid $color--black;
      position: relative;
    }
    .address {
      margin-right: 15px;
      @media screen and (max-width: 860px) {
        margin-right: 0;
      }
      .left {
        padding: 42px 50px;
        background-color: $color--black;
        color: $color--white;
        input {
          padding-left: 40px;
          max-width: 200px;
          @media screen and (max-width: 1440px) {
            padding-left: 30px;
          }
          background-image: url(../img/ico-search-grey.svg);
          background-position: 9px 8px;
          background-repeat: no-repeat;
          @media screen and (max-width: 1440px) {
            max-width: 245px;
            background-position: 8px 6px;
            background-size: 24px;
          }
        }
        @media screen and (max-width: 1440px) {
          padding: 22px 40px;
        }
        h1 {
          margin-top: 10px;
        }
        span {
          @media screen and (max-width: 1440px) {
            font-size: 15px;
          }
        }
        // @media screen and (max-width: 1366px) {
        //   padding: 20px;
        // }
        p {
          max-width: 290px;
          @media screen and (max-width: 1440px) {
            max-width: 260px;
            font-size: 16px;
          }
          &.with-icon {
            position: relative;
            padding-left: 20px;
            &:before {
              content: url(../img/ico-pin.png);
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        .input-group {
          @media screen and (max-width: 860px) {
            margin-top: 20px;
            flex-wrap: wrap;
            input {
              width: calc(100% - 125px);
              margin-right: 0;
            }
            .btn {
              height: 47px;
              width: 125px;
              margin-top: -1px;
            }
          }
        }
      }
      .detail-location {
        padding: 70px;
      }
      .map.custom {
        .mapouter {
          iframe#gmap_canvas {
            @media screen and (max-width: 1440px) {
              width: 430px;
              height: 430px;
            }
            @media screen and (max-width: 800px) {
              width: 380px;
              height: 380px;
            }
          }
        }

        @media screen and (max-width: 860px) {
          width: 100%;
        }
        iframe#gmap_canvas {
          @media screen and (max-width: 860px) {
            width: 100%;
          }
        }
      }
    }
    .contact-us {
      max-width: 446px;
      padding: 70px;
      @media screen and (max-width: 1440px) {
        padding: 30px 40px;
      }

      // @media screen and (max-width: 1366px) {
      //   padding: 60px;
      // }
      @media screen and (max-width: 860px) {
        padding: 10px 30px;
        max-width: calc(100% - 72px);
        margin: auto;
        margin-top: 32px;
      }
      h1 {
        @media screen and (max-width: 1440px) {
          margin-top: 50px;
        }
      }

      .link {
        @media screen and (max-width: 1440px) {
          margin-top: 30px;
        }
      }

      &.small {
        padding: 30px 50px;
        h1 {
          font-size: 28px;
          font-family: 'Roboto-Bold';
          margin-top: 50px;
        }
        p {
          font-size: 16px;
          line-height: 23px;
        }
        .link {
          margin: 40px 0 20px;
        }

        @media screen and (max-width: 860px) {
          padding: 30px 20px;
          max-width: calc(100% - 0px);
          margin: auto;
          margin-top: 32px;
          h1 {
            margin-top: 0;
          }
          .link {
            margin-top: 20px;
          }
        }
      }
    }
    .detail-location-list {
      margin-bottom: 30px;
      .item {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-start;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 25px;
        }
        img {
          width: 25px;
          max-width: 25px;
        }
        span {
          display: block;
        }
        p {
          width: calc(100% - 80px);
          max-width: 390px;
          font-family: 'Roboto-Bold';
          span {
            font-family: 'Roboto';
          }
        }
      }
    }
  }
  &.subscribe {
    padding-top: 0;
    .flex-container {
      @media screen and (max-width: 860px) {
        flex-wrap: wrap;
      }
    }
    h1 {
      margin-bottom: 50px;
      @media screen and (max-width: 1440px) {
        margin-bottom: 30px;
      }
    }
    .left {
    }
    .right {
      @media screen and (max-width: 1440px) {
        max-width: 20%;
      }
      @media screen and (max-width: 860px) {
        max-width: 100%;
        padding: 0;
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
      }
      h1 {
        margin-bottom: 70px;
        text-align: right;
        @media screen and (max-width: 1440px) {
          margin-bottom: 50px;
        }
        @media screen and (max-width: 860px) {
          text-align: left;
          margin-top: 0px;
          margin-bottom: 10px;
          font-size: 21px;
          width: 60%;
        }
      }
      .socmed {
        @media screen and (max-width: 1440px) {
          a {
            img {
              max-width: 35px;
              max-height: 35px;
            }
          }
        }
        @media screen and (max-width: 860px) {
          padding: 0 10px;
          width: 100%;
          a {
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }
        }
      }
      max-width: 25%;
    }
    .input-group {
      input {
        width: 100%;
        max-width: 640px;
        height: 74px;
        background-color: $color--grey-soft;
        border: 1.5px solid $color--grey-soft;
        margin-right: 0;
        padding-left: 5%;
        padding-right: 5%;
        @media screen and (max-width: 1440px) {
          max-width: 510px;
          height: 60px;
        }
      }
      .btn {
        width: 246px;
        height: 74px;
        border: 1.5px solid $color--grey-soft;
        margin-left: 0px;
        background-color: transparent;
        @media screen and (max-width: 1440px) {
          height: 60px;
          width: 206px;
          img {
            max-width: 36px;
          }
        }
        .link {
          margin: auto;
        }
      }

      @media screen and (max-width: 860px) {
        flex-wrap: wrap;
        justify-content: center;
        input {
          max-width: calc(100% - 110px) !important;
          height: 44px;
        }
        .btn {
          width: 106px;
          height: 44px;
          margin-top: 0;
          .link {
            img {
              display: none;
            }
            span {
              margin-left: 0;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
  &.hero-2 {
    height: auto;
    .flex-container {
      @media screen and (max-width: 640px) {
        flex-direction: column;
        .home-product {
          .home-product-content {
            position: relative;
            left: auto;
            top: auto;
            margin: auto;
            transform: none;
            text-align: center;
          }
        }
      }
    }
    .breadcrumb {
      .flex-container {
        @media screen and (max-width: 640px) {
          flex-direction: row;
        }
      }
    }
    .swiper-container {
      height: 534px;
      @media screen and (max-width: 1440px) {
        height: 380px;
      }
      @media screen and (max-width: 1024px) {
        height: 340px;
      }
      .swiper-wrapper {
        .swiper-slide {
          .content {
            top: 80%;
            left: 0;
            transform: translateY(-50%);
            padding-left: 72px;
            font-size: 28px;
            @media screen and (max-width: 1440px) {
              top: 70%;
              span {
                &:last-child {
                  font-weight: lighter;
                }
                strong {
                  font-weight: 600;
                }
              }
            }

            &:after {
              content: '';
              position: absolute;
              top: 15px;
              left: 0;
              width: 50px;
              border-bottom: 0.7px solid $color--white;
            }
          }
        }
      }
    }
    .home-product {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 12.5%;
        transform: translate(-50%, -50%);
        width: 93px;
        border-bottom: 1px solid $color--black;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .home-product-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 473px;
        @media screen and (max-width: 1024px) {
          width: 100%;
          max-width: 400px;
        }
        @media screen and (max-width: 860px) {
          max-width: 320px;
          h1 {
            font-family: 'Roboto';
          }
        }
        @media screen and (max-width: 320px) {
          max-width: 280px;
        }
        p {
          line-height: 28px;
          @media screen and (max-width: 1440px) {
            line-height: 22px;
          }
        }
      }
    }
  }
  &.hero-3 {
    height: 673px;
    @media screen and (max-width: 1440px) {
      height: 593px;
    }
    @media screen and (max-width: 1024px) {
      height: 533px;
    }
    @media screen and (max-width: 640px) {
      height: 255px;
    }
    .img {
      position: relative;
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      max-height: 600px;
      @media screen and (max-width: 1440px) {
        max-height: 520px;
      }
      @media screen and (max-width: 1024px) {
        max-height: 460px;
      }
      @media screen and (max-width: 640px) {
        max-height: 200px;
      }
      h1 {
        font-size: 90px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color--white;
        line-height: 1;
        margin: 0;
        @media screen and (max-width: 1440px) {
          font-size: 70px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 55px;
        }
        @media screen and (max-width: 640px) {
          font-size: 28px;
        }
      }
    }
  }
  &.hero-4 {
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .content {
            p {
              @media screen and (max-width: 1440px) {
                font-size: 18px;
                margin-bottom: 50px;
              }
            }
          }
        }
      }
    }

    .breadcrumb {
      @media screen and (min-width: 641px) {
        display: none;
      }
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 55px;
    }
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .content {
            left: 50%;
            top: 50%;
            max-width: 888px;
            h2 {
              font-size: 75px;
              margin-bottom: 30px;
              margin-top: 0;
              @media screen and (max-width: 1440px) {
                font-size: 55px;
                margin-bottom: 20px;
                margin-top: 30px;
              }
              @media screen and (max-width: 640px) {
                font-size: 18px;
                margin-bottom: 10px;
                margin-top: 0;
              }
            }
            p {
              @media screen and (max-width: 640px) {
                margin: auto;
                max-width: 235px;
              }
            }
          }
        }
      }
    }
  }
  &.hero-small {
    height: auto;
    .img {
      position: relative;
      width: 100%;
      height: 285px;
      background-position: center;
      background-size: cover;
      @media screen and (max-width: 640px) {
        height: 160px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.6);
      }

      .content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 9;
        &-2{
          h1{
            margin: auto;
          }
        }
        h1,
        span {
          font-family: 'Roboto';
          color: $color--white;
        }
        span {
          display: block;
          margin-top: 25px;
        }
        h1 {
          margin-top: 10px;
          font-size: 900;
          width: max-content;
        }
      }
    }
  }
  &.detail-1 {
    padding-top: 0;
    padding-bottom: 0;
    .flex-container {
      justify-content: center;
      .right {
        width: 100%;
        .tabcontent,
        .tabcontent2 {
          max-width: 980px;
          padding: 30px 10px;
          @media screen and (max-width: 1440px) {
            max-width: 830px;
          }
          .accordion {
            padding: 14px;
          }
        }
        .tabcontent2 {
          padding: 0 10px;
        }
        .tabcontent2 {
          .accordion.active {
            background-color: transparent;
          }
          // .flex-container {
          //   @media screen and (min-width: 1920px) {
          //     // max-width: unset;
          //     justify-content: space-between;
          //   }
          // }
          .panel {
            padding: 0;
            .panel-container {
              position: relative;
              display: block;
              padding-top: 20px;
              padding-bottom: 30px;
              @media screen and (max-width: 640px) {
                padding-top: 0;
              }
              .flex-container {
                justify-content: space-between;
                .left {
                  @media screen and (max-width: 1440px) {
                    max-width: 410px;
                  }
                }
              }
              p {
                width: 530px;
                max-width: unset;
                margin-right: 60px;
                @media screen and (max-width: 1440px) {
                  max-width: 410px;
                  width: 100%;
                  padding-right: 20px;
                  margin-left: 15px;
                }
                @media screen and (max-width: 640px) {
                  margin-top: 0;
                }
              }
            }
          }
          .image {
            &.right {
              @media screen and (max-width: 1440px) {
                max-width: 320px;
              }
            }
            img {
              max-width: 400px;
              @media screen and (max-width: 1440px) {
                max-width: 300px;
                float: right;
                margin-left: 25px;
              }
            }
          }
        }
        .flex-container {
          max-width: 900px;
        }
      }
      .left {
        width: 100%;
        max-width: 628px;
        @media screen and (max-width: 1440px) {
          max-width: 360px;
        }
        @media screen and (max-width: 640px) {
          max-width: unset;
        }
        img {
          max-width: 410px;
          @media screen and (max-width: 1440px) {
            max-width: 330px;
          }
          @media screen and (max-width: 1024px) {
            max-width: 280px;
          }
          @media screen and (max-width: 640px) {
            max-width: unset;
            width: 100%;
          }
        }

        height: max-content;
        .tab-header {
          position: relative;
          padding-left: 34px;
          @media screen and (max-width: 640px) {
            &:after {
              content: url(../img/ico-chevron-bottom-small-grey.svg);
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          h4 {
            margin-top: 18px;
            margin-bottom: 16px;
            font-size: 28px;
          }
        }
        .tab-body {
          padding-left: 0;
          list-style: none;
          margin-bottom: 0;
          @media screen and (max-width: 640px) {
            height: 0;
            overflow: hidden;
            margin: 0;
          }
        }

        .tab-container {
          max-width: 400px;
          float: right;
          border: 1px solid $color--grey;
          width: 100%;

          &.collapsed {
            @media screen and (max-width: 640px) {
              .tab-header {
                &:after {
                  content: url(../img/ico-chevron-top-small-grey.svg);
                }
              }
            }
            .tab-body {
              height: 100%;
            }
          }
          @media screen and (max-width: 1440px) {
            max-width: 360px;
          }
          @media screen and (max-width: 640px) {
            max-width: unset;
          }
          &.blank {
            max-width: unset;
            border-right: none;
            border-left: none;
            height: 70px;

            @media screen and (max-width: 640px) {
              display: none;
            }
          }
        }
      }
      .right {
        @media screen and (max-width: 1440px) {
          max-width: 775px;
        }
      }
      .description {
        h5 {
          font-size: 28px;
          margin-bottom: 30px;
          margin-top: 0;
          @media screen and (max-width: 1440px) {
            font-size: 24px;
          }
        }
        p {
          font-size: 16px;
          line-height: 28px;
          max-width: 391px;
          @media screen and (max-width: 1440px) {
            font-size: 14px;
            max-width: 330px;
            line-height: 22px;
          }
        }
      }
    }

    @media screen and (max-width: 820px) and (min-width: 645px) {
      .flex-container {
        .right {
          max-width: 460px;
          .flex-container {
            flex-direction: column;

            .image {
              img {
                max-width: 360px;
              }
            }

            .description {
              padding-top: 30px;
              h5 {
                margin-bottom: 10px;
              }
              p {
                max-width: 425px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 640px) {
      .flex-container {
        flex-direction: column;
        .right {
          padding-top: 30px;
          padding-top: 0;
          margin-top: -1px;
          .tabcontent {
            padding: 30px 20px;
          }
          h5 {
            font-size: 22px;
            margin-bottom: 10px;
            margin-top: 30px;
          }
          p {
            font-family: 'Roboto';
          }
        }
      }
    }
  }
  &.event {
    padding-top: 0;
    .tab {
      background-color: $color--grey;
      @media screen and (max-width: 640px) {
        background-color: transparent;
      }
    }
  }
  &.about-us {
    padding-top: 0;
  }
  &.contact-us {
    padding: 0;
    .tab {
      .container {
        @media screen and (max-width: 820px) {
          transform: none;
          padding-left: 0;
          margin-left: 0;
          a {
            margin-left: 20px;
            margin-right: 20px;
          }
        }
      }
    }
    &.dealer {
      .paragraph {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
      .form-container {
        margin-bottom: 10px;
        .block {
          margin: auto;
        }
      }
    }
  }

  &.news-event {
    .container-1024 {
      position: relative;
      width: 100%;
      display: flex;
      flex: 1 1 auto;
      margin: auto;
      justify-content: space-between;
    }
    &.detail {
      h2 {
        font-family: 'Roboto';
        font-weight: bolder;
        font-size: 26px !important;
      }
      .left {
        width: 100%;
        max-width: calc(100% - 420px);
        padding-right: 20px;
        img {
          width: 100%;
        }
        p {
          font-size: 16px;
          line-height: 29px;
          font-family: 'Roboto';
          padding: 4px 0;
          margin: 0;
        }
      }
      .right {
        width: 100%;
        max-width: 380px;
        .card-news.summary {
          margin-top: 30px;
          padding-bottom: 20px;
        }
      }

      @media screen and (max-width: 640px) {
        .container-1024 {
          flex-direction: column;

          .left {
            padding: 0;
            max-width: unset;
          }
          .right {
            max-width: unset;
          }
          h2 {
            font-size: 22px !important;
          }
        }
      }
    }
  }
}

.no-border {
  border: none !important;
}

.flex-container {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  &.space-between {
    justify-content: space-between;
  }
  &.sm-space-around {
    @media screen and (max-width: 1024px) {
      justify-content: space-around !important;
    }
  }
  &.center {
    justify-content: center;
  }
  .block {
    width: 100%;
  }
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.container {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 1365px;
  @media screen and (max-width: 1440px) {
    max-width: 1024px;
  }
  // @media screen and (max-width: 1366px) {
  //   max-width: 1200px;
  // }
  @media screen and (max-width: 1024px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 860px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 320px) {
    padding: 0 7px;
  }

  &-1024 {
    max-width: 1024px;
    @media screen and (max-width: 860px) {
      padding: 0 15px;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.paragraph {
  &:not(:last-child) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 640px) {
    img {
      max-width: 100%;
    }
  }
  h3 {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 12px;
    font-family: 'Roboto-Bold';
  }
  p {
    font-size: 16px;
    font-family: 'Roboto';
    margin: 0 auto;
    padding: 4px 0;
    line-height: 29px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      margin-bottom: 35px;
    }
  }
  img {
    display: block;
    margin: auto;
  }
}

.say-thanks {
  font-size: 45px;
  font-family: 'Roboto';
  font-weight: 600;
  text-align: center;
  max-width: 720px;
  margin: auto;
  display: block;

  @media screen and (max-width: 640px) {
    max-width: 320px;
    font-size: 32px;
  }
}

iframe {
  &#gmap_canvas {
    width: 425px;
    height: 425px;

    @media screen and (max-width: 1440px) {
      width: 315px;
      height: 315px;
    }

    @media screen and (max-width: 860px) {
      width: 275px;
    }

    // @media screen and (max-width: 1366px) {
    //   width: 380px;
    //   height: 400px;
    // }
    @media screen and (max-width: 640px) {
      height: 240px;
    }
  }
}

.border-top-bottom {
  border-top: 1px solid $color--grey;
  border-bottom: 1px solid $color--grey;
}

.devider {
  position: relative;
  margin: 50px auto;
  width: 100%;
  min-height: 1px;

  @media screen and (max-width: 640px) {
    margin: 25px auto;
  }

  &.line-1 {
    border-bottom: 1px solid $color--grey;
  }

  &.no-margin {
    margin: auto !important;
  }
}

.form-container {
  justify-content: space-between;
  .block {
    max-width: 450px;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    width: calc(100% - 15px);
    margin: auto;
  }
}

.center {
  margin: auto;
}

.md-max-60 {
  @media screen and (min-width: 1280px) {
    max-width: 60%;
  }
}

.text-editor {
  font-family: 'Roboto-light';
  padding-right: 5%;
  img {
    max-width: 100%;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
  ul {
    li {
      font-size: 16px;
      margin-bottom: 5px;
      p {
        line-height: 14px;
      }
    }
  }
}

//article
.article{
  &-detail{
    &-pagination{
      margin: 2rem 0 0;
      padding: 2rem 0 0;
      border-top: 1px solid $color--grey;
      &-block{
        display: block;
        position: relative;
        &:hover{
          .article{
            &-detail{
              &-pagination{
                &-block{
                  &__content{
                    h5{
                      color: $color--red;
                    }
                  }
                }
              }
            }
          }
        }
        &--right{
          text-align: right;
          @media screen and (max-width: 425px) {
            margin-top: 10px;
          }
          .article{
            &-detail{
              &-pagination{
                &-block{
                  &__content{
                    img{
                      left: auto;
                      right: -10%;
                      transform: rotate(0deg);
                    }
                    &-image{
                      > .inner {
                        margin-right: 0;
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        p{
          font-size: 13px !important;
          font-style: italic;
        }
        &__content{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            position: absolute;
            left: -10%;
            display: block;
            width: 21px !important;
            transform: rotate(180deg);
            @media screen and (max-width: 425px) {
              display: none;
            }
          }
          &-image{
            position: relative;
            display: block;
            width: 40%;
            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: (28 / 43) * 100%;
            }
            > .inner {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-size: cover;
              background-position: center;
              margin-right: 10px;
            }
          }
          h5{
            width: calc(100% - 40%);
            margin: 0;
            transition: color ease 200ms;
            font-weight: bold;
            line-height: 1.4;
          }
        }
      }
    }
    .left{
      padding-right: 0 !important;
      max-width: 80% !important;
      width: 80% !important;
      margin: auto;
      @media screen and (max-width: 425px) {
        max-width: 100% !important;
        width: 100% !important;
      }
      .text-editor{
        padding-right: 0;
      }
    }
  }
  &-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  &-col{
    width: 50%;
    padding: 0 15px;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  &-block{
    display: block;
    text-align: center;
    margin-bottom: 2.5rem;
    &:hover{
      h4{
        color: $color--red;
      }
      .article{
        &-block{
          &__btn{
            // color: $color--red;  
            img{
              transform: translateX(5px);
            }
          }
        }
      }
    }
    &__image{
      position: relative;
      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (246 / 493) * 100%;
      }
      > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center;
      }
    }
    &__btn{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-top: 16px;
      transition: color ease 200ms;
      img{
        width: 15px;
        margin-left: 6px;
        transition: all ease 200ms;
      }
    }
    h4{
      font-size: 20px;
      font-weight: bold;
      margin: .9rem 0 .3rem;
      transition: color ease 200ms;
    }
    p{
      line-height: 1.65;
      margin: .5rem 0 0;
    }
  }
}

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  a{
    display: block;
    padding: .4rem .6rem;
    border: 1px solid $color--black;
    margin: .2rem;
    font-size: 13px;
    transition: all ease 200ms;
    &:hover{
      background-color: $color--black;
      color: $color--white;
    }
    &.active{
      background-color: $color--black;
      color: $color--white;
    }
  }
}

.block-img--2{
  img{
    position: relative !important;
    left: auto !important;
    top: auto !important;
    transform: translate(0%,0%) !important;
    width: 100% !important;
    height: auto  !important;
    max-width: 100% !important;
    max-height: auto  !important;
  }
}

.article-detail{
  &.detail {
    .left {
      img {
        width: 100%;
      }
      p {
        font-size: 16px;
        line-height: 29px;
        font-family: 'Roboto';
        padding: 4px 0;
        margin: 0;
      }
      ol,ul,li,a,span,strong,i,u,{
        font-size: 16px;
        line-height: 29px;
        font-family: 'Roboto';
      }
    }
  }
}