.accordion {
  background-color: transparent;
  color: $color--black-soft-2;
  cursor: pointer;
  padding: 14px 34px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border-top: 1px solid $color--grey;
  &:nth-child(5) {
    border-bottom: 1px solid $color--grey;
  }
}

.active,
.accordion:hover {
  background-color: $color--grey-soft-2;
  color: $color--black;
}

a.accordion {
  display: inline-block;
  background: transparent;
  color: $color--black-soft-2;
  &:after {
    display: none;
  }
}

.accordion {
  position: relative;
  font-size: 21px;
  line-height: 42px;
  padding-right: 50px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 32px;
  }
  &:after {
    content: url("../img/ico-plus-black.svg");
    width: 24px;
    height: 24px;
    color: $color--black;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    position: absolute;
    right: 24px;
    @media screen and (max-width: 1440px) {
      transform: scale(0.6) translateY(4px);
    }
  }
  &.active:after {
    content: url("../img/ico-minus-black.svg");
  }
}

.panel {
  padding: 0 34px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  list-style: none;
  // padding-left: 0;

  li {
    &.active {
      background-color: transparent;
      a {
        color: $color--red;
        &:after {
          content: "";
          position: absolute;
          left: -34px;
          top: 50%;
          transform: translateY(-50%);
          border-bottom: 1px solid $color--red;
          min-height: 1px;
          width: 20px;
        }
      }
    }
    a {
      font-size: 18px;
      line-height: 42px;
      color: $color--black-soft-2;
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      display: inline-block;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 32px;
      }

      &:hover {
        color: $color--red;
        &:after {
          content: "";
          position: absolute;
          left: -34px;
          top: 50%;
          transform: translateY(-50%);
          border-bottom: 1px solid $color--red;
          min-height: 1px;
          width: 20px;
        }
      }
    }
  }
}
