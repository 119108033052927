.btn {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  display: block;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }

  &.color--red {
    background-color: $color--red;
    color: $color--white;
  }
  &.color--black {
    background-color: $color--black;
    color: $color--white;
    width: 266px;
    height: 67px;
    font-size: 19px;
    font-family: "Roboto";
    img {
      max-width: 9px;
      margin-left: 20px;
      margin-top: 3px;
    }
  }
}
a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 1 1 auto;
}

.link {
  color: $color--white;
  display: flex;
  align-items: center;
  margin: 60px 0 20px 0;
  width: max-content;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    margin: 40px 0 20px 0;
    img {
      max-width: 42px;
    }
  }

  img {
    transition-duration: 0.4s;
    width: auto !important;
  }

  span {
    transition-duration: 0.4s;
  }

  @keyframes arrow {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  &:hover {
    img {
      animation: arrow 0.8s forwards;
    }
    span {
      transform: translateX(10px);
      transition-delay: 0.6s;
    }
  }

  @media screen and (max-width: 860px) {
    margin: 24px 0 20px 0;
  }
  span {
    margin-left: 15px;
    display: inline-block;
  }
  &.black {
    span {
      color: $color--black;
    }
  }
  &.center {
    margin: 60px auto 20px auto;
    @media screen and (max-width: 1440px) {
      margin: 40px auto 20px auto;
    }
    @media screen and (max-width: 860px) {
      margin: 24px auto 20px auto;
    }
  }
}
