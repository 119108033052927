.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100px;
  background-color: $color--white;
  text-align: center;
  overflow: hidden;
  z-index: 999;
  border-bottom: 1px solid $color--grey;
  overflow: inherit;

  @media screen and (max-width: 1440px) {
    min-height: 70px;
  }

  @media screen and (max-width: 1024px) {
    min-height: unset;
    height: 67px;
  }

  .header-container {
    position: relative;
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1440px) and (min-width: 821px) {
      max-width: unset;
      width: calc(100% - 70px);
      a {
        // font-size: 11px;
      }
    }
    @media screen and (max-width: 1366px) {
      // max-width: 1200px;
      a {
        font-size: 11px;
      }
    }

    .block {
      // max-width: 160px;

      &.menu,
      &.action {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        align-items: center;
      }

      &.action {
        justify-content: space-between;
        max-width: 215px;
        margin-left: 30px;
        overflow: inherit;

        @media screen and (max-width: 1440px) {
          max-width: 175px;
        }

        @media screen and (max-width: 1024px) {
          margin-left: 0;
        }
      }
      &.menu {
        justify-content: space-around;
        .link-menu {
          display: flex;
          height: 100px;
          align-items: center;
          position: relative;
          top: 1px;
          @media screen and (max-width: 1440px) {
            height: 70px;
          }
          &.active {
            border-bottom: 1.5px solid $color--red;
            background-color: transparent;
          }
        }
        @media screen and (max-width: 1440px) {
          .link-menu {
            // font-size: 12px;
          }
        }
        @media screen and (max-width: 1024px) {
          display: none;
          order: 3;
          flex-direction: column;
          background-color: $color--white;
          text-align: left;
          align-self: flex-start;
          padding-top: 19px;
          padding-bottom: 80px;

          .link-menu {
            line-height: 51px;
            font-size: 19px;
            padding-left: 45px;
            font-family: 'Roboto';
            margin-left: -2px;
            height: auto;
            display: block;

            &.active {
              border-left: 2.3px solid $color--red;
              background-color: transparent;
              border-bottom: none;
            }
          }
        }
        @media screen and (max-width: 375px) {
          .link-menu {
            padding-left: 15px;
          }
        }
      }
    }

    a {
      font-size: 17px;
    }

    .logo {
      margin-right: 69px;
      @media screen and (max-width: 1440px) {
        // transform: scale(0.8);
        margin-right: 50px;
        img {
          max-width: 170px;
        }
      }
      @media screen and (max-width: 1366px) {
        // transform: scale(0.75);
        margin-right: 30px;
      }
      @media screen and (max-width: 1024px) {
        transform: none;
        img {
          max-width: 125px;
          margin-left: 20px;
        }
      }
      @media screen and (max-width: 320px) {
        display: inline-block;
        max-width: 95px;
        overflow: hidden;
      }
    }
    .language {
      margin-left: 35px;
      position: relative;
      cursor: pointer;

      .selected-language {
        position: relative;
        input[type='radio'] {
          position: absolute;
          transform: scale(0);
          visibility: hidden;
        }
      }
      .select-language {
        position: absolute;
        top: calc(100% + 0px);
        right: -37px;
        width: max-content;
        background: $color--white;
        padding: 18px 20px;
        opacity: 0;
        visibility: hidden;
        border: 1.5px solid $color--grey;
        @media screen and (max-width: 1440px) {
          padding: 14px 16px;
          .list {
            font-size: 12px;
          }
        }
        .list {
          display: flex;
          flex: 1 1 auto;
          cursor: pointer;
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        @media screen and (max-width: 375px) {
          right: -30px;
        }
      }

      span.flag-language {
        display: block;
        width: 23px;
        height: 23px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media screen and (max-width: 1440px) {
          width: 19px;
          height: 19px;
        }
      }
      span.text-language {
        display: block;
        margin-left: 15px;
      }

      &.active {
        background-color: transparent;
        .selected-language {
          .arrow {
            transform: scale(0.6) translateY(-50%) rotate(180deg);
          }
        }
        .select-language {
          top: calc(100% + 20px);
          opacity: 1;
          visibility: visible;
          @media screen and (max-width: 1440px) {
            top: calc(100% + 25px);
          }
        }
      }

      @media screen and (max-width: 1024px) {
        margin-left: 15px;
        margin-right: 30px;
      }

      .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(0.6);
        right: -25px;
      }
    }
    .btn-search,
    .btn-burger {
      border: none;
      padding: 0;
      margin: 0;
      position: relative;
      height: 100px;
      width: 100px;
      background-color: $color--grey;
      @media screen and (max-width: 1440px) {
        height: 70px;
        width: 70px;
      }
      @media screen and (max-width: 1024px) {
        width: 67px;
        height: 67px;
      }
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: $color--grey-dark;
      }

      &.btn-burger {
        background-color: transparent;
        display: none;
        img {
          width: 17px;
        }
        @media screen and (max-width: 1024px) {
          display: block;
        }
      }
    }
  }

  &.show-mobile-menu {
    @media screen and (max-width: 1024px) {
      .header-container {
        .logo {
          margin-left: -230px;
        }
        .menu {
          display: block;
          border: 1.5px solid $color--grey;
        }
      }
    }
  }
}
