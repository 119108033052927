.card {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: $color--white;
  h1 {
    font-size: 90px;
    margin-bottom: 52px;
    position: relative;
    font-family: 'Roboto-light';
    @media screen and (max-width: 1440px) {
      font-size: 70px;
      margin-bottom: 22px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 93px;
      height: 1px;
      border-bottom: 1px solid $color--white;
      bottom: -20px;
      left: 0;
      @media screen and (max-width: 1440px) {
        bottom: -10px;
        width: 73px;
      }
    }
  }
  h4 {
    font-size: 28px;
    margin-bottom: 0;
    @media screen and (max-width: 1440px) {
      margin-top: 10px;
      font-size: 21px;
    }
  }

  &-product {
    height: 445px;
    width: 445px;

    @media screen and (max-width: 1440px) {
      width: 335px;
      height: 335px;
    }
    @media screen and (max-width: 1024px) {
      width: 322px;
      height: 322px;
    }

    // @media screen and (max-width: 1366px) {
    //   height: 390px;
    //   width: 390px;
    // }

    &-width {
      width: 675px;
      @media screen and (max-width: 1440px) {
        width: 507px;
        height: 300px;
      }
      @media screen and (max-width: 1024px) {
        width: 487px;
        height: 280px;
      }
    }

    &:hover {
      .btn {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.2s;
      }
      &:before {
        opacity: 0;
      }
      .content {
        * {
          transform: translateY(-20px);
          opacity: 0;
          visibility: hidden;
        }
        h1 {
          transition-delay: 0;
        }
        h4 {
          transition-delay: 0.1s;
        }
      }
    }

    .btn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 88px;
      height: 88px;
      background-color: $color--white;
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;
      -webkit-box-shadow: 10px 10px 31px -6px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 31px -6px rgba(0, 0, 0, 0.75);
      box-shadow: 10px 10px 31px -6px rgba(0, 0, 0, 0.75);
      img {
        transition-duration: 0.5s;
      }
      &:hover {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .content {
      position: absolute;
      left: 40px;
      bottom: 32px;
      @media screen and (max-width: 1440px) {
        left: 26px;
        bottom: 20px;
      }
      * {
        transition-duration: 0.3s;
        visibility: visible;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color--black, $alpha: 0.5);
      transition-duration: 0.3s;
    }
  }
  &-event {
    max-width: 640px;
    height: 600px;
    width: 100%;
    @media screen and (max-width: 1440px) {
      height: 450px;
    }
    @media screen and (max-width: 1024px) {
      height: 320px;
    }

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // height: 200px;
      padding: 31px 40px;
      @media screen and (max-width: 1440px) {
        padding: 11px 30px;
      }
      background-color: rgba($color--black, $alpha: 0.5);
      .time {
        font-size: 14px;
        margin-bottom: 14px;
      }
      h4 {
        margin-top: 10px;
        min-height: 65px;
        @media screen and (max-width: 1440px) {
          font-size: 21px;
        }
        // @media screen and (max-width: 1366px) {
        //   font-size: 20px;
        // }
      }
      a.link {
        margin-top: 30px;
        @media screen and (max-width: 1440px) {
          margin-top: 10px;
        }
      }
    }
  }
  &-product-detail {
    .flex-container {
      max-width: unset !important;
      justify-content: space-between !important;
    }
    .card-description {
      width: 100%;
      max-width: 628px;
      height: max-content;

      p {
        width: 530px;
        max-width: unset;
        margin-right: 60px;
        font-size: 16px;
        line-height: 28px;
      }
    }
    .card-image {
      padding-top: 25px;
      width: 100%;
      img {
        max-width: 400px;
        @media screen and (max-width: 1440px) {
          max-width: 300px;
        }
      }
    }
  }
  &-news {
    display: flex;
    flex: 1 1 auto;
    @media screen and (max-width: 640px) {
      flex-direction: column;
    }

    &.summary {
      flex-direction: column;
      max-width: 380px;
      .block-img {
        img {
          width: 100%;
        }
      }
      .block-detail {
        padding-left: 0;
        margin-top: 15px;
        .detail-title {
          font-size: 16px;
        }
        .btn-detail {
          font-size: 16px;
          margin: auto;
          max-width: max-content;
        }
        .devider {
          margin: 30px auto;
        }
      }
    }

    .block {
      width: 100%;
      &-date {
        max-width: max-content;
        padding-right: 100px;
        text-align: center;
        @media screen and (max-width: 1440px) {
          padding-right: 70px;
        }
        @media screen and (max-width: 640px) {
          position: absolute;
          left: 0px;
          top: 0px;
          padding: 10px;
          background-color: rgba(255, 255, 255, 0.5);
        }
        h1 {
          color: $color--black;
          margin-top: 0;
          font-weight: normal;
          &:after {
            border-bottom: 1px solid $color--black;
            left: 50%;
            transform: translateX(-50%);
            width: 93px;
          }
          @media screen and (max-width: 640px) {
            font-size: 26px;
            margin-bottom: 0;
            &:after {
              display: none;
            }
          }
        }
        h4 {
          color: $color--black;
          @media screen and (max-width: 640px) {
            font-size: 9px;
            margin-top: 0;
          }
        }
      }
      &-img {
        // min-width: 490px;
        // min-height: 333px;
        width: 100%;
        background-size: cover;
        background-position: center;
        // overflow: hidden;
        position: relative;

        img {
          position: relative;
          // position: absolute;
          // left: 50%;
          // top: 50%;
          // transform: translate(-50%, -50%);
          // width: 490px;
          // height: 333px;
          width: 100%;
          // max-width: 100%;
          // max-height: 333px;
          border: none;
          // opacity: 0;
          // transition-duration: 0.3s;
          // margin-top: -30px;
          &.loaded {
            // margin-top: 0;
            // opacity: 1;
          }
        }
        // @media screen and (max-width: 1440px) {
        //   min-width: unset;
        //   min-height: unset;
        //   width: 100%;
        //   height: 240px;
        // }
        // @media screen and (max-width: 820px) {
        //   width: 100%;
        //   height: 200px;
        // }
      }
      &-detail {
        padding-left: 56px;
        color: $color--black;
        @media screen and (max-width: 1440px) {
          padding-left: 36px;
        }
        @media screen and (max-width: 640px) {
          padding-left: 0;
          padding-top: 24px;
        }
        .detail-title {
          font-size: 25px;
          font-family: 'Roboto';
          max-width: 442px;
          margin-bottom: 32px;
          @media screen and (max-width: 1440px) {
            margin-bottom: 15px;
            font-size: 19px;
          }
          @media screen and (max-width: 640px) {
            // font-family: "Roboto-Bold";
            font-size: 16px;
          }
        }
        .detail-subtitle {
          font-size: 18px;
          max-width: 344px;
          margin-bottom: 33px;
          font-family: 'Roboto-light';
          @media screen and (max-width: 1440px) {
            max-width: 250px;
            font-size: 12px;
            margin-bottom: 15px;
          }
          @media screen and (max-width: 640px) {
            display: none;
          }
        }
        .detail-summary {
          font-size: 18px;
          font-family: 'Roboto-light';
          max-width: 650px;
          margin-bottom: 30px;
          @media screen and (max-width: 1440px) {
            font-size: 12px;
          }
          @media screen and (max-width: 640px) {
            line-height: 1.5;
            font-family: 'Roboto';
            font-size: 13px;
            padding-right: 10px;
          }
        }
        .btn-detail {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          margin-top: 30px;
          img {
            margin-left: 15px;
          }
          @media screen and (max-width: 640px) {
            justify-content: center;
          }
        }
      }
    }
  }
  &-history {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    justify-content: space-between;
    color: $color--black;
    align-items: center;
    padding: 35px 0;
    .detail {
      max-width: 640px;
      h3 {
        font-size: 26px;
        margin: 0;
      }
      p {
        font-size: 16px;
        line-height: 29px;
      }
    }
    .img {
      width: 232px;
      height: 145px;
      background-position: center;
      background-size: cover;
    }
  }
}

.slide-in {
  animation: slideIn 0.5s ease forwards;
}

@keyframes slideIn {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
