$color--white: #ffffff;
$color--black: #000000;
$color--black-soft: #25292c;
$color--black-soft-2: #757575;
$color--grey: #eaeaea;
$color--grey-dark: #dfdfdf;
$color--grey-dark-2: #676767;
$color--grey-soft-2: #f3f3f3;
$color--grey-soft: #f0f0f0;
$color--red: #e70012;
