.table {
  border: 1px solid black;
  &__header {
    background-color: black;
    color: white;
  }
  &__body {
  }

  .row {
    display: grid;
    grid-template-columns: 50px 280px 200px auto;
    grid-gap: 10px;
    border-bottom: 1px solid black;
    @media screen and (max-width: 820px) {
      grid-template-columns: 30px 110px auto auto;
      grid-gap: 5px;
    }
    @media screen and (max-width: 540px) {
      grid-template-columns: 30px 110px auto 120px;
      grid-gap: 5px;
    }
    @media screen and (max-width: 320px) {
      grid-template-columns: 30px 70px 80px 80px;
    }
    .col {
      padding: 18px 22px;
      line-height: 1.5;
      font-size: 16px;
      font-family: 'Roboto-medium';
      span.desktop {
        display: inline;
      }
      span.mobile {
        display: none;
      }
      @media screen and (max-width: 820px) {
        font-size: 15px;
        padding: 18px 15px;
        span.desktop {
          display: none;
        }
        span.mobile {
          display: inline;
        }
      }
    }
  }
}

.table-title {
  font-family: 'Roboto-bold';
  font-size: 24px;
  margin-bottom: 26px;
}
