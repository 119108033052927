.tab {
  overflow: hidden;
  border-bottom: 1px solid $color--grey;
  border-top: 1px solid $color--grey;
  color: $color--black;
  &:first-child {
    padding-left: 0;
  }

  @media screen and (max-width: 820px) {
    overflow: auto;
    .container-1024 {
      width: max-content;
      padding-left: 100px;
    }
    .container {
      transition-duration: 0.3s;
      transform: translateX(100px);
      width: max-content;
    }
  }
}

/* Style the buttons inside the tab */
.tab button,
.tab a {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 24px 2px;
  margin: 0 22px;
  transition: 0.3s;
  font-size: 17px;
  background: transparent;
  font-family: 'Roboto';
  @media screen and (max-width: 640px) {
    padding: 16px 2px;
  }
}

/* Change background color of buttons on hover */
.tab button:hover,
.tab a:hover {
  // background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active,
.tab a.active {
  font-weight: bold;
}
.tab button.active.border,
.tab a.active.border {
  border-bottom: 2px solid $color--red;
}

.tab button.active.border2,
.tab a.active.border2 {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 15px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $color--red;
    @media screen and (max-width: 640px) {
      bottom: 12px;
    }
  }
}

/* Style the tab content */
.tabcontent,
.tabcontent2 {
  display: none;
  padding: 40px;
  width: 100%;

  @media screen and (max-width: 640px) {
    padding: 40px 0;
  }
  .left {
    max-width: 510px;
    width: 100%;
  }
  .image {
    padding-top: 25px;
    width: 100%;
  }
}
