.footer {
  width: 100%;
  padding-top: 9px;
  padding-bottom: 25px;
  color: $color--white;
  background-color: $color--black-soft;

  @media screen and (max-width: 860px) {
    display: none;
  }

  h5 {
    font-size: 24px;
    line-height: 30px;
    font-family: "Roboto";
  }
  span {
    font-size: 16px;
    font-family: "Roboto";
    line-height: 42px;
  }
  a {
    font-size: 16px;
    font-family: "Roboto-light";
    color: $color--white;
    line-height: 34px;
    margin-bottom: 15px;
    display: block;
    &:hover {
      color: rgba($color--white, $alpha: 0.5);
      &:after {
        color: rgba($color--white, $alpha: 1);
      }
    }
  }
  .copyright {
    margin-top: 100px;
    div:first-child {
      max-width: 512px;
    }
    a:not(:last-child) {
      position: relative;
      &:after {
        content: "|";
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
