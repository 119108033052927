* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 18px;
}
body {
  font: 18px "Roboto";
  color: $color--black;
}
// body,
// html {
//   min-height: 100vh;
//   overflow: hidden;
// }

a {
  color: $color--black;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-family: "Roboto-light";
}
h1 {
  font-size: 48px;
  margin: 0.67em 0;
  @media screen and (max-width: 1440px) {
    font-size: 28px;
  }
  @media screen and (max-width: 860px) {
    font-size: 28px;
  }
}
h2 {
  font-size: 46px;
  @media screen and (max-width: 1440px) {
    font-size: 26px;
  }
}
h4 {
  font-size: 28px;
}
p {
  font-size: 18px;
  font-family: "Roboto-light";
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
}
