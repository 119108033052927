.input-group {
  display: flex;
  flex: 1 1 auto;

  input[type='email'] {
    @media screen and (max-width: 1366px) {
      max-width: 520px !important;
    }
  }

  .btn {
    width: 140px;
    @media screen and (max-width: 1440px) {
      width: 110px;
    }
  }
}

input[type='text'],
input[type='password'],
input[type='email'] {
  background: transparent;
  outline: none;
  height: 45px;
  margin-right: 18px;
  padding-left: 5px;
  padding-right: 5px;
  @media screen and (max-width: 1440px) {
    height: 35px;
  }

  &::placeholder {
    font-style: italic;
  }

  &.dark {
    border: 1px solid $color--white;
    color: $color--white;
  }
}

.input-wrapper {
  margin-bottom: 37px;
  position: relative;
  label {
    font-family: 'Roboto-Bold';
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 13px;
    display: block;
  }
  input,
  select {
    height: 55px;
    background-color: transparent;
    border: 1px solid $color--black;
    width: 100%;
    padding: 14px 22px;
    outline: none;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../img/ico-chevron-bottom-small-grey.svg);
    background-position: 95% 50%;
    background-repeat: no-repeat;

    &:active {
      background-image: url(../img/ico-chevron-top-small-grey.svg);
    }
  }
  textarea {
    background-color: transparent;
    border: 1px solid $color--black;
    width: 100%;
    padding: 12px 22px;
    resize: none;
  }
}
