.breadcrumb-container {
  &.border-bottom {
    border-bottom: 1px solid $color--grey;
  }
}
.breadcrumb {
  padding: 27px 0;
  position: relative;
  left: 29.5%;
  width: max-content;
  @media screen and (max-width: 1440px) {
    left: 31%;
  }
  @media screen and (max-width: 1366px) {
    left: 28.5%;
  }
  @media screen and (max-width: 1280px) {
    left: 27.5%;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    left: auto;

    .flex-container {
      justify-content: center;
    }
  }
  @media screen and (max-width: 640px) {
    padding: 18px 0;
  }

  a {
    font-size: 16px;
    color: $color--grey-dark-2;
    position: relative;
    &:not(:last-child) {
      margin-right: 80px;
      &:after {
        content: url(../img/ico-chevron-right-small-grey.svg);
        position: absolute;
        right: -45px;
        top: 50%;
        transform: translateY(-50%) scale(0.8);
        width: 9px;
        height: 18px;
      }
    }
    @media screen and (max-width: 640px) {
      max-width: 35%;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;

      text-overflow: ellipsis;
      &:not(:last-child) {
        margin-right: 50px;
        overflow: visible;
        &:after {
          right: -30px;
        }
      }
    }
  }
}
